module services {
    export module tariff {
        export class tariffCountryService implements interfaces.tariff.ITariffCountryService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all TariffCountry for a dropdown/autocomplete list (Based on the Current Entity!!)
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TariffCountry/GetDropdownList", {
                   
                });
            }

            //Gets all Tariff Books that are either In Progress or Publised in GTS (not entity specific!!)
            getActiveTariffCountries(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TariffCountry/GetActiveTariffCountries", {
                   
                });
            }

            

            //Gets list of all TariffCountry for a dropdown/autocomplete list (Based on the Transaction Entity!!)
            getDropdownListByTransactionEntity(entityId:number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TariffCountry/GetDropdownListByTransactionEntity", {
                    entityId:entityId
                });
            }

            
            getTariffCountrySetting(entityId:number,countryId:number): ng.resource.IResourceClass<interfaces.tariff.ITariffCountrySetting> {

                return this.$resource<interfaces.tariff.ITariffCountrySetting>(this.ENV.DSP_URL + "TariffCountry/GetTariffCountrySetting", {
                    entityId: entityId,
                    countryId: countryId
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            params: {
                                Id: 0
                            },
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }
            
        }
    }
    angular.module("app").service("tariffCountryService", services.tariff.tariffCountryService);
}